import './App.css';
import React from "react";


function App() {
  return (
    <div className="App">
     <ServerDownPage/>
    </div>
  );
}

const ServerDownPage = () => {
  return (
    <div style={styles.container}>
      <div style={styles.svgContainer}>
        {/* SVG illustration */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          width="200"
          height="200"
        >
          <path
            d="M32 2C15.43 2 2 15.43 2 32s13.43 30 30 30 30-13.43 30-30S48.57 2 32 2zm0 56C17.663 58 6 46.337 6 32S17.663 6 32 6s26 11.663 26 26-11.663 26-26 26z"
            fill="#d9534f"
          />
          <path
            d="M32 16c-1.105 0-2 .895-2 2v16c0 1.105.895 2 2 2s2-.895 2-2V18c0-1.105-.895-2-2-2zM32 44c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
            fill="#d9534f"
          />
        </svg>
      </div>
      <h1 style={styles.heading}>Website Unavailable </h1>
      <p style={styles.message}>
        Oops! It seems our website is currently unavailable. Please try again later.
      </p>
      <button style={styles.button} onClick={() => window.location.reload()}>
        Retry
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f8f9fa",
    fontFamily: "Arial, sans-serif",
  },
  svgContainer: {
    marginBottom: "20px",
  },
  heading: {
    fontSize: "2rem",
    color: "#d9534f",
  },
  message: {
    fontSize: "1rem",
    color: "#6c757d",
    margin: "10px 0",
    fontWeight: 'bold',
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#d9534f",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};






export default App;
